<template>
  <header class="header">
    <TheLogo />
  </header>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.header {
  @include container;
  text-align: center;
}
</style>
